<template>
<div style='margin-bottom: 5rem;'>
  <div class="header" style='position: unset'>
    <van-nav-bar
      :title="$t('tuandui')"
      left-arrow
      @click-left="$router.go(-1)"
    />
  </div>
  <van-grid :column-num="1">
    <van-grid-item>
      <van-row>
        <p style='margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('yaoqingma') }}</p>
        <b>{{ invitecode }}</b>
      </van-row>
      <van-row>
        <span style='margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('yaoqinglink') }}：</span>
        <b>{{ invitelink }}</b>
      </van-row>
      <van-row style='width: 100%'>
        <van-button
          class="submitBtns icon-copy2"
          @click.prevent="copyurl"
          :data-clipboard-text="invitelink"
        >{{
            $t('dianjifuzhi')
          }}</van-button>
      </van-row>
    </van-grid-item>
  </van-grid>
  <van-grid :column-num="1">
    <van-grid-item>
      <van-row>
        <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('yongjins') }}({{ currency.toUpperCase() }})</p>
        <b>{{ yongjins | priceformat}}</b>
      </van-row>
      <van-row style='width: 100%'>
        <van-button
          class="submitBtns"
          to='/rewardlog'
        >{{
            $t('yongjinjilu')
          }}</van-button>
      </van-row>
    </van-grid-item>
  </van-grid>
  <van-grid :column-num="2">
    <van-grid-item>
      <van-row>
        <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('tuanduipersons') }}</p>
        <b>{{ users | personformat}}</b>
      </van-row>
    </van-grid-item>
    <van-grid-item>
      <van-row>
        <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('youxiaoperson') }}</p>
        <b>{{ efusers | personformat}}</b>
      </van-row>
    </van-grid-item>
  </van-grid>
  <van-grid :column-num="1">
    <van-grid-item>
      <van-row>
<!--        <p style='margin-bottom: 0.3rem;font-size: 1rem;float: left;'>{{ $t('xiajituanduide') }}</p>-->
        <h1>{{ $t('xiajituanduide') }}</h1>
      </van-row>
    </van-grid-item>
  </van-grid>
  <van-tabs v-model="active" type='card'>
    <van-tab :title="$t('1tuandui')">
      <van-grid :column-num="3">
        <van-grid-item>
          <van-row>
            <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('tuuipersons') }}</p>
            <b>{{ user_1.length | personformat}}</b>
          </van-row>
        </van-grid-item>
        <van-grid-item>
          <van-row>
            <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('youxiaoperson') }}</p>
            <b>{{ user_ef_1.length | personformat}}</b>
          </van-row>
        </van-grid-item>
        <van-grid-item>
          <van-row>
            <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('zongyeji') }}</p>
            <b>{{ tze_1 | priceformat}}</b>
          </van-row>
        </van-grid-item>
      </van-grid>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
      >
        <van-cell v-for='item in user_1'>
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div style='display: flex;justify-content: space-between'>
              <span class="custom-title" style='margin-right: 2rem'>ID:{{ item.id }}</span>
              <span v-if='item.user' class="custom-title" style='margin-right: 2rem'>{{ $t("yonghum") }}:{{ item.user }}</span>
              <span v-if='item.email' class="custom-title" style='margin-right: 2rem'>{{ $t("youxiang") }}:{{ item.email }}</span>
              <van-tag v-if='item.is_effect == "1"' type="danger">{{ $t("youxiaoperson") }}</van-tag>
            </div>
            <div style='display: flex;justify-content: space-between'>
              <span class="custom-title">{{ $t("xingmmm") }}:{{ item.realname }}</span>
              <span class="custom-title">{{ $t("zhucetime") }} : {{ item.createtime | dateformat}}</span>
            </div>
            <div class='vanboxline'></div>
          </template>
        </van-cell>
      </van-list>
    </van-tab>
    <van-tab :title="$t('2tuandui')">
      <van-grid :column-num="3">
        <van-grid-item>
          <van-row>
            <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('tuuipersons') }}</p>
            <b>{{ user_2.length | personformat}}</b>
          </van-row>
        </van-grid-item>
        <van-grid-item>
          <van-row>
            <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('youxiaoperson') }}</p>
            <b>{{ user_ef_2.length | personformat}}</b>
          </van-row>
        </van-grid-item>
        <van-grid-item>
          <van-row>
            <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('zongyeji') }}</p>
            <b>{{ tze_2 | priceformat}}</b>
          </van-row>
        </van-grid-item>
      </van-grid>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
      >
        <van-cell v-for='item in user_2'>
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div style='display: flex;justify-content: space-between'>
              <span class="custom-title" style='margin-right: 2rem'>ID:{{ item.id }}</span>
              <span v-if='item.user' class="custom-title" style='margin-right: 2rem'>{{ $t("yonghum") }}:{{ item.user }}</span>
              <span v-if='item.email' class="custom-title" style='margin-right: 2rem'>{{ $t("youxiang") }}:{{ item.email }}</span>
              <van-tag v-if='item.is_effect == "1"' type="danger">{{ $t("youxiaoperson") }}</van-tag>
            </div>
            <div style='display: flex;justify-content: space-between'>
              <span class="custom-title">{{ $t("xingmmm") }}:{{ item.realname }}</span>
              <span class="custom-title">{{ $t("zhucetime") }} : {{ item.createtime | dateformat}}</span>
            </div>
            <div class='vanboxline'></div>
          </template>
        </van-cell>
      </van-list>
    </van-tab>
    <van-tab :title="$t('3tuandui')">
      <van-grid :column-num="3">
        <van-grid-item>
          <van-row>
            <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('tuuipersons') }}</p>
            <b>{{ user_3.length | personformat}}</b>
          </van-row>
        </van-grid-item>
        <van-grid-item>
          <van-row>
            <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('youxiaoperson') }}</p>
            <b>{{ user_ef_3.length | personformat}}</b>
          </van-row>
        </van-grid-item>
        <van-grid-item>
          <van-row>
            <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('zongyeji') }}</p>
            <b>{{ tze_3 | priceformat}}</b>
          </van-row>
        </van-grid-item>
      </van-grid>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
      >
        <van-cell v-for='item in user_3'>
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div style='display: flex;justify-content: space-between'>
              <span class="custom-title" style='margin-right: 2rem'>ID:{{ item.id }}</span>
              <span v-if='item.user' class="custom-title" style='margin-right: 2rem'>{{ $t("yonghum") }}:{{ item.user }}</span>
              <span v-if='item.email' class="custom-title" style='margin-right: 2rem'>{{ $t("youxiang") }}:{{ item.email }}</span>
              <van-tag v-if='item.is_effect == "1"' type="danger">{{ $t("youxiaoperson") }}</van-tag>
            </div>
            <div style='display: flex;justify-content: space-between'>
              <span class="custom-title">{{ $t("xingmmm") }}:{{ item.realname }}</span>
              <span class="custom-title">{{ $t("zhucetime") }} : {{ item.createtime | dateformat}}</span>
            </div>
            <div class='vanboxline'></div>
          </template>
        </van-cell>
      </van-list>
    </van-tab>
  </van-tabs>
  <div>

  </div>
</div>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  name: 'Index',
  data() {
    return {
      selectLang: '',
      loading: false,
      finished: true,
      invitecode: '',
      invitelink: '',
      selectLangOption: [
        { text: '中文', value: 'zh' },
        { text: 'English', value: 'en' },
        { text: 'русский язык', value: 'ru' },
        { text: 'Français', value: 'fr' },
        { text: 'Español', value: 'es' },
        { text: 'Italiano', value: 'it' }
      ],
      lang: localStorage.getItem('lang') || 'zh',
      currency: localStorage.getItem('currency') || 'USDT',
      user_1: [],
      user_2: [],
      user_3: [],
      user_ef_1: [],
      user_ef_2: [],
      user_ef_3: [],
      tze_1: 0,
      tze_2: 0,
      tze_3: 0,
      users: 0,
      efusers: 0,
      yongjins: 0,

      score: 0,
      currencyhuilv: 1,
      notifyshow: false,
      websocket: null,
      wk: null,
      active: 0
    }
  },
  created() {
    this.getteamdata()
    this.getinfo()
  },
  methods: {
    // 复制地址
    copyurl() {
      const clipboard = new Clipboard('.icon-copy2')
      clipboard.on('success', e => {
        this.$toast.success(this.$t('common.success'))
      })
    },
    // 获取首页数据
    async getteamdata() {
      const { data } = await this.$http.get('/home/user/team')
      if (data) {
        if (data.code === 200) {
            this.user_1 = data.data.data.users_1
            this.user_2 = data.data.data.users_2
            this.user_3=data.data.data.users_3
            this.user_ef_1=data.data.data.users_ef_1
            this.user_ef_2=data.data.data.users_ef_2
            this.user_ef_3=data.data.data.users_ef_3
            this.tze_1=data.data.data.tze_1
            this.tze_2=data.data.data.tze_2
            this.tze_3=data.data.data.tze_3
            this.users=data.data.data.users
            this.efusers=data.data.data.ef_users
            this.yongjins= data.data.data.yongjins
          this.invitecode = data.data.data.invite_code
          this.invitelink = "https://" + window.location.hostname + "/#/register?code=" + this.invitecode
        }
      }
    },
    async getinfo() {
      const { data } = await this.$http.get('/home/user/index')
      if (data) {
        if (data.code === 200) {
          let that = this
          this.score = +data.data.userinfo.credit_score
          this.userinfo = data.data.userinfo
          this.balance = data.data.balance
          this.tongji = data.data.tongji
          let units = data.data.unitinfo
          units.forEach(function(value, index, units) {
            if (units[index].unit == that.currency) {
              that.currencyhuilv = units[index].huilv
            }
          })
        }
      }
    },
    // 去设置页
    tosetting() {
      this.$router.push('/person/setting')
    },
    // 退出
    logout() {
      window.localStorage.removeItem('token')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
// 弹出层
.top {
  height: 18%;
  display: flex;
  align-items: center;
  //justify-content: center;
.top-left {
  margin-right: 2%;
  float: left;
  padding: 1rem;
}
.top-right {
  text-align: left;
  font-size: 1.33333rem;
  line-height: 2.13333rem;
}
}

//.van-cell {
//  height: 3.2rem;
//  font-size: 0.93333rem;
//}
.van-cell__title {
  text-align: left;
}
.van-cell__value {
  color: #000;
}
.submitBtns {
  //margin-bottom: 20%;
  margin-top: 0.5rem;
  width: 40%;
  //letter-spacing: 0.53333rem;
  height: 2rem;
  font-size: 0.93333rem;
  color: #fff;
  background-color: #004ea3;
  border-radius: 0.53333rem;
  border: none;
  //box-shadow: 0 0 1.33333rem #004ea3;
}
/deep/ .van-tabs__nav--card .van-tab.van-tab--active {
  color: white;
  background-color: #004ea3;
}
/deep/ .van-tabs__nav--card .van-tab {
  border-right: 1px solid #004ea3;
}
/deep/ .van-tabs__nav--card {
  border: 1px solid #004ea3;
}
/deep/ .van-tabs__nav--card .van-tab {
  color: #004ea3;
}

.vanboxline {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  /* background: -webkit-repeating-linear-gradient(135deg,#ff6c6c 0,#ff6c6c 20%,transparent 0,transparent 25%,#1989fa 0,#1989fa 45%,transparent 0,transparent 50%); */
  background: repeating-linear-gradient(-45deg,#ff6c6c 0,#ff6c6c 20%,transparent 0,transparent 25%,#1989fa 0,#1989fa 45%,transparent 0,transparent 50%);
  background-size: 80px;
  content: '';
}

</style>
